<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Customer" title="Email Blast" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="row q-col-gutter-md">
      <div class="col-xs-12 col-sm-12 col-md-6">
        <FilterCard title="Create Email Blast">
          <q-form ref="filterForm" @submit="onSubmit" @reset="onReset">
            <div class="q-mb-lg col q-gutter-md">
              <div>
                <SelectSegments
                  v-model="segment"
                  :values="segment"
                  @updateSegment="$event => (segment = $event)"
                  tabindex="1"
                />
              </div>

              <div>
                <FormInput
                  label="* Subject"
                  v-model="subject"
                  :rules="[VALIDATION.required]"
                  outlined
                  tabindex="2"
                />
              </div>

              <div>
                <FormEditor
                  placeholder="* Message"
                  v-model="message"
                  :rules="[VALIDATION.required]"
                  outlined
                  tabindex="3"
                />

                <div class="q-pt-sm text-grey-6 text-caption">
                  <div class="q-mb-sm row items-center justify-between">
                    <div class="row items-center q-gutter-sm">
                      <q-icon
                        class="cursor-pointer"
                        name="help"
                        color="primary"
                        size="xs"
                        @click="onToggleMessageModal"
                      >
                        <q-tooltip
                          anchor="center right"
                          self="center left"
                          :offset="[10, 10]"
                        >
                          Note on message
                        </q-tooltip>
                      </q-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="q-ml-sm">
                <FormToggle
                  label="Send immediately"
                  v-model="sendNow"
                  tabindex="6"
                />
              </div>

              <div v-if="!sendNow">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-12 col-md-6">
                    <FormDate
                      label="* Date To Send"
                      mask="date"
                      v-model="sendDate"
                      :value="sendDate"
                      :rules="[VALIDATION.required]"
                      :options="date => date >= minDate"
                      @input="onSelectSendDate"
                      tabindex="4"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-6">
                    <FormTime
                      label="* Time To Send"
                      mask="time"
                      v-model="sendTime"
                      :value="sendTime"
                      :rules="[VALIDATION.required]"
                      @input="onSelectSendTime"
                      tabindex="5"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="q-mt-lg row items-center justify-end q-col-gutter-md">
              <div class="col-xs-12 col-sm-12 col-md-6 text-right">
                <Button
                  variant="primary"
                  label="Create Email Blast"
                  icon="send"
                  :loading="loadingCreate"
                  :disabled="loadingCreate"
                  type="submit"
                  tabindex="7"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6">
        <Table
          :rows="listTableData"
          :columns="listTableHeader"
          :loading="loadingLists"
          customBody
        >
          <template v-slot:body="props">
            <q-tr>
              <q-td>
                {{ props.row.id }}
              </q-td>
              <q-td>
                {{ props.row.segment }}
              </q-td>
              <q-td>
                <div class="w-64 overflow-hidden">
                  <p class="truncate">{{ props.row.subject }}</p>
                </div>
              </q-td>
              <q-td>
                <q-badge
                  :color="props.row.status === 'Executed' ? 'success' : 'info'"
                >
                  {{ props.row.status }}
                </q-badge>
              </q-td>
              <q-td>
                {{ props.row.sentAt }}
              </q-td>
              <q-td>
                {{ props.row.createdAt }}
              </q-td>
            </q-tr>
          </template>
        </Table>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Email Blast</h5>
    <p>Send an email by segment/group.</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Fields</h6>
    <p>
      <strong>Segment:</strong> The name of the segment you want created before.
    </p>
    <p><strong>Subject:</strong> The summary or title of your email.</p>
    <p><strong>Message:</strong> The content of your email.</p>
    <p><strong>Date:</strong> The date you want to send the email.</p>
    <p><strong>Time:</strong> The time you want to send the email.</p>
  </Modal>

  <Modal
    :show="showMessageModal"
    header="Guide for message syntax"
    width="700px"
    cancelLabel="Close"
    @close="onToggleMessageModal"
    closeOnly
  >
    <p>
      Please do note that SYNTAX base values are not included in the character
      count due to its dynamic value and will be replaced with
      appropriate/actual value upon sending email message.
    </p>
    <br />
    <p>Here are the allowed syntax:</p>
    <br />
    <p>
      <strong>@firstname</strong> - This will replace the actual firstname of
      the customer
    </p>
    <p>
      <strong>@lastname</strong> - This will replace the actual lastname of the
      customer
    </p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard } from '@/components/cards'
import {
  FormInput,
  FormDate,
  FormTime,
  FormEditor,
  FormToggle
} from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import SelectSegments from '@/components/customs/SelectSegments'

import { DATE, FORMAT, VALIDATION, Toast, fetchData, getIconUrl } from '@/tools'

export default {
  name: 'CustomerEmailBlast',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    FormInput,
    FormDate,
    FormTime,
    FormEditor,
    FormToggle,
    Button,
    Table,
    Modal,
    SelectSegments
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataCreate,
      error: errorCreate,
      loading: loadingCreate,
      post: postCreate
    } = fetchData()

    const showModal = ref(false)
    const showMessageModal = ref(false)
    const filterForm = ref(null)
    const segment = ref(null)
    const subject = ref(null)
    const message = ref('')
    const sendDate = ref(null)
    const sendTime = ref(null)
    const sendNow = ref(false)
    const minDate = DATE.toFriendlyDate(new Date())
    const listTableData = ref([])

    const listTableHeader = ref([
      {
        name: 'id',
        label: '#',
        field: 'id',
        align: 'center',
        sortable: true
      },
      {
        name: 'segment',
        label: 'Segment',
        field: 'segment',
        align: 'left',
        sortable: true
      },
      {
        name: 'subject',
        label: 'Subject',
        field: 'subject',
        align: 'left',
        sortable: true
      },
      {
        name: 'status',
        label: 'Status',
        field: 'status',
        align: 'center',
        sortable: true
      },
      {
        name: 'sentAt',
        label: 'Sent At',
        field: 'sentAt',
        align: 'center',
        sortable: true
      },
      {
        name: 'createdAt',
        label: 'Created At',
        field: 'createdAt',
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => listTableData.value && listTableData.value.length > 0
    )
    const noData = computed(
      () => listTableData.value && listTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onToggleMessageModal = () => {
      showMessageModal.value = !showMessageModal.value
    }

    const getTableLists = () => {
      getLists('/v1/campaign/email-blast')
    }

    const onSubmit = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          const dateTime = sendNow.value
            ? new Date()
            : `${sendDate.value} ${sendTime.value}`
          const payload = {
            customer_segment_id: segment.value,
            subject: subject.value,
            email_message: message.value,
            send_now: sendNow.value ? 'T' : 'F',
            date_to_send: DATE.toFriendlyDateTime(dateTime)
          }
          postCreate('v1/campaign/email-blast', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      segment.value = null
      subject.value = null
      message.value = null
      sendDate.value = null
      sendTime.value = null
      sendNow.value = false
    }

    const onSelectSendDate = data => {
      sendDate.value = data
    }

    const onSelectSendTime = data => {
      sendTime.value = data
    }

    onBeforeMount(() => {
      getTableLists()

      watch([dataLists, errorLists, loadingLists], () => {
        if (!loadingLists.value) {
          if (errorLists?.value) {
            showToast('There was a problem fetching the lists.', 'danger')
          } else if (dataLists?.value) {
            listTableData.value =
              dataLists?.value?.map(item => ({
                id: item?.id,
                segment: item?.segment,
                subject: item?.subject,
                status: item?.status,
                sentAt: item?.date_sent
                  ? DATE.toFriendlyDateTime(item?.date_sent)
                  : null,
                createdAt: DATE.toFriendlyDateTime(item?.created_at)
              })) ?? []
          }
        }
      })

      watch([dataCreate, errorCreate, loadingCreate], () => {
        if (!loadingCreate.value) {
          if (errorCreate?.value) {
            showToast('There was a problem creating Email Blast.', 'danger')
          } else if (dataCreate?.value) {
            if (dataCreate?.value?.customer_segment_id?.length > 0) {
              showToast('Email Blast created successfully!', 'success')
              getTableLists()
              onReset()
            }
          }
        }
      })
    })

    return {
      showModal,
      showMessageModal,
      filterForm,
      segment,
      subject,
      message,
      sendDate,
      sendTime,
      sendNow,
      minDate,
      listTableHeader,
      listTableData,
      loadingCreate,
      loadingLists,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onToggleMessageModal,
      onSubmit,
      onReset,
      onSelectSendDate,
      onSelectSendTime
    }
  }
}
</script>
