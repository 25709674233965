<template>
  <FormSelect
    label="* Segment"
    :values="values"
    :options="options"
    :rules="[VALIDATION.required]"
    :loading="loading"
    :disabled="loading"
    :multiple="multiple"
    @updateStore="onUpdateSegment"
    @click="onUpdateSegment"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectSegments',
  inheritAttrs: true,
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])

    const onUpdateSegment = data => {
      emit('updateSegment', data)
    }

    onBeforeMount(() => {
      get('v1/customer-segmentation/manual')

      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast(
              error?.value?.errors
                ? error?.value?.errors[0]?.msg
                : error?.value?.message ??
                    'There was a problem fetching segments.',
              'danger'
            )
          } else if (data?.value) {
            options.value = data?.value?.map(item => ({
              label: item?.name,
              value: item?.id,
              description: item?.desc
            }))
          }
        }
      })
    })

    return { options, loading, VALIDATION, onUpdateSegment }
  }
}
</script>
