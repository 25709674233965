<template>
  <customer-email-blast />
</template>

<script>
import CustomerEmailBlast from '@/components/pages/customer/CustomerEmailBlast'

export default {
  components: {
    CustomerEmailBlast
  }
}
</script>
